.place-subname{
   
 
    overflow: hidden;
    text-overflow: ellipsis;
    // display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    line-height: 1rem !important;
    max-height: 3rem;
}
.place-desc{
   font-size: 12px;
   font-weight: 400;
    line-height: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-height: 1rem;
    max-height: 4rem;
}
.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded{
    min-height: 40px !important;
}
.css-o4b71y-MuiAccordionSummary-content.Mui-expanded{
    margin: 0 !important;
}
.card{
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)) !important
}
.preview{
    border: 1px dashed #A1A1A1;
    background: #F2F2F2;
}
#raised-button-file.drag-active {
    background-color: #ffffff;
  }