.sidebar-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 200px;
  }

  .sidebar-footer {
    align-self: flex-start;
    margin-bottom: 30px;
  }

  .selected {
    background-color: #EE4266;
    border-radius: 0px 40px 40px 0px;
    max-width: 166px;
  }