.appName{
    font-style: normal;
    font-weight: 700 !important;
    font-size: 48px !important;
    line-height: 75px !important;
    font-family: 'Montserrat' !important;
    
    color: #FFFFFF;
    }
    .subname{
        font-style: normal;
    font-weight: 500 !important;
    font-size: 48px !important;
    line-height: 75px !important;
    font-family: 'Montserrat' !important;
    color: #FFFFFF;
    }
    .joinText{
        margin-top: 4px !important;
        font-style: normal;
    font-weight: 500 !important;
    font-size: 20px !important;
    line-height: 36px !important;
    font-family: 'Open Sans', sans-serif !important;
    }
    .clickText{
      
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 24px !important;
    margin-top: 10px !important;
    font-family: 'Open Sans', sans-serif !important;
    }
    .benifts{
       
    font-style: normal;
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 24px !important;
    margin-left: 30px !important;
    font-family: 'Open Sans', sans-serif !important;
    }
    .para{
        
        font-style: normal;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 32px !important;
    font-family: 'Open Sans', sans-serif !important;
    }
    .appNamelast{
        font-style: normal;
        font-weight: 700 !important;
        font-size: 32px !important;
        line-height: 54px !important;
        font-family: 'Montserrat' !important;
        
        color: #FFFFFF;
        }
        .subnamelast{
            font-style: normal;
        font-weight: 500 !important;
        font-size: 32px !important;
        line-height: 54px !important;
        font-family: 'Montserrat' !important;
        color: #FFFFFF;
        }