.administration-text {
    color: #A1A1A1; 
    font-Weight: 600px;
}
.otpView{
    width: 2.4rem !important;
    height: 2.4rem;
   margin: 0 0.5rem;
    font-size: 1rem;
   padding: 2px;
    margin-top: 20px;
    // border-bottom: 1px solid rgba(0,0,0,.3) !important;
    border:none;
    background-color: #F2F2F2;
    border-radius: 5px;
  }