@import "rsuite/dist/rsuite.css";

.imageDiv{
    background-color: rgba(248,199,22,0.3);
    width: 78px;
    height: 78px;
    border-radius: 39px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
}
.bottomIcons{
    position: absolute;
    top: 20px;
    right: 20px;
}
.rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value{
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.87);
}
.rs-picker-has-value.rs-picker-cleanable .rs-picker-toggle.rs-btn{
height: 40px !important;
}