.terms{
    font-family: 'Open Sans', sans-serif !important;
   text-align: left !important;
   color: #000 !important;
   h1{
       line-height: 90px !important;
       margin-left: -6px;
       margin-bottom: 8px !important;
       margin-top: 2px !important;
   }
   p{
       font-size: 20px !important;
       line-height: 30px !important;
   }
   b {
    font-size: 20px !important;
}
}
