.privacy{
    font-family: 'Open Sans', sans-serif !important;
   text-align: left !important;
   color: #000 !important;
 h1{
     margin-left: -6px;
     margin-bottom: 8px !important;
     margin-top: 2px !important;
 }
}
